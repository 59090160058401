::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.templatescroll {
  max-height: 550px;
  overflow: auto;
  padding: 5px;
}
.custom-datepicker {
  width: 100%;
}
.custom-datepicker > div {
  width: 100%;
}
.css-1ct7ii0-Stack-root > :not(style) + :not(style) {
  margin-left: 16px;
}
.usercustom-datepicker {
  width: 100%;
}
.usercustom-datepicker > div {
  width: 50%;
  padding: 0 5px;
}
.file-input [type='file'] {
  height: 0;
  overflow: hidden;
  width: 0;
  box-sizing: content-box;
  display: none;
}
[type='file'] + label {
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  border: 1px solid rgba(145, 158, 171, 0.32);
  width: 50%;
  border-radius: 10px;
}
.country-phone {
  width: 100%;
  display: flex;
  position: relative;
}
.phoneinput {
  text-align: left;
  margin: 0;
  height: 56px;
  /* padding-left: 80px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid; */
  border-width: 1px;
  /* overflow: hidden; */
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 0 8px 8px 0 !important;
  border-color: rgba(145, 158, 171, 0.32);
  width: 100%;
}
.phoneinput .css-5afllg-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 0 8px 8px 0 !important;
}
.phoneinput:focus-visible {
  outline: none;
}
.codeselect {
  text-align: left;
  margin: 0;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 8px;
  border-color: rgba(145, 158, 171, 0.32);
  background: #fdfdfd;
  width: 100px;
  height: 56px;
  display: block;
  z-index: 1;
  border-radius: 8px 0 0 8px;
  padding: 5px;
  border: 1px solid #eeeeee;
}
.codeselect:focus-visible {
  border-color: rgba(145, 158, 171, 0.32);
  outline: none;
}
.closeicon {
  position: absolute;
  right: -6px;
  top: -3px;
}
